<template>
  <div class="hello">
    <div class="titleBox">
      <div class="login">
        <img src="../assets/image/login.png" alt="" />
        <div
          style="line-height: 80px"
          @click="handleNav('https://sh.egzss.com/admin/login/index', false)"
        >
          登录/注册
        </div>
      </div>
      <div class="Fun">
        <div
          class="FunDetail"
          @click="handleNav('https://sh.egzss.com/admin/login/index', false)"
        >
          <img src="../assets/image/xiaoqu.png" alt="" />
          <div>小区治理</div>
        </div>
        <div
          class="FunDetail"
          @click="handleNav('https://sh.egzss.com/admin/login/index', false)"
        >
          <img src="../assets/image/rencai.png" alt="" />
          <div>人才服务</div>
        </div>
        <div
          class="FunDetail"
          @click="handleNav('https://sh.egzss.com/admin/login/index', false)"
        >
          <img src="../assets/image/xiaoxin.png" alt="" />
          <div>孝心食堂</div>
        </div>
        <!-- <div class="FunDetail" @click="handleNav('/guolong', true)">
          <img src="../assets/guolong.gif" alt="" />
          <div>国隆数字</div>
        </div> -->
        <div
          class="FunDetail"
          @click="handleNav('https://sh.egzss.com/admin/login/index', false)"
        >
          <img src="../assets/image/jiazheng.png" alt="" />
          <div>家政服务</div>
        </div>
      </div>
    </div>

    <!-- 消息 -->
    <div class="newMain">
      <!-- 资讯 -->
      <div class="information">
        <div class="informationTitleBox">
          <div>最新资讯</div>
          <div class="more" @click="$navTo('informationList')">
            查看更多<i class="el-icon-arrow-right" style="font-size: 16px"></i>
          </div>
        </div>
        <div class="newDetailBox">
          <!-- 左边轮播图 -->
          <el-carousel
            :interval="5000"
            arrow="hover"
            height="340px"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="(item, index) in alertsList"
              :key="index + 'srsjgackgh'"
            >
              <div
                class="newImg"
                @click="$navTo('information', { id: item.id })"
              >
                <el-image
                  style="width: 480px; height: 100%"
                  :src="$showImg(item.img)"
                  fit="scale - down"
                ></el-image>
                <div class="PMTitleBox">
                  <div>{{ index + 1 }}/{{ alertsList.length }}</div>
                  <div class="zxTltle">
                    {{ item.title }}
                  </div>
                  <div></div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <!-- 右侧资讯列表 -->
          <ul class="newList">
            <li
              v-for="(item, index) in alertsList"
              :key="'gaynzzm' + index"
              @click="$navTo('information', { id: item.id })"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 超值购 -->
      <div class="information">
        <div class="informationTitleBox">
          <div>众享超值购</div>
          <div
            class="more"
            @click="$navTo('premiumMall', { activeIndex: '2' })"
          >
            查看更多<i class="el-icon-arrow-right" style="font-size: 16px"></i>
          </div>
        </div>

        <!-- 商品 -->
        <div class="goodsList">
          <div
            class="goodsDetail"
            v-for="(item, index) in couponList"
            :key="'gnyamm' + index"
            @click="$navTo('goodsDetail', { id: item.id })"
          >
            <!-- <el-image
              style="width: 130px; height: 130px"
              :src="$showImg(item.coupon_img)"
              fit="scale - down"
            ></el-image> -->
            <div class="imgsBox">
              <img :src="$showImg(item.coupon_img)" alt="加载失败" />
            </div>

            <div class="goodsName">{{ item.coupon_title }}</div>
            <div class="shopName">{{ item.merchant.merchant_name }}</div>
            <div class="goodsPrice">
              <span style="font-size: 14px">￥</span>{{ item.price }}
            </div>
          </div>
        </div>
      </div>

      <!-- 秒杀等 -->
      <div class="imgsBox">
        <img
          src="../assets/image/miaosha.png"
          alt=""
          @click="$navTo('premiumMall', { activeIndex: '3' })"
        />
        <img
          src="../assets/image/goodGoods.png"
          alt=""
          @click="$navTo('premiumMall', { activeIndex: '4' })"
        />
        <img
          src="../assets/image/tuangou.png"
          alt=""
          @click="$navTo('premiumMall', { activeIndex: '5' })"
        />
        <img
          src="../assets/image/fenxiao.png"
          alt=""
          @click="$navTo('premiumMall', { activeIndex: '6' })"
        />
      </div>

      <!-- 众享商城 -->
      <div class="information">
        <div class="informationTitleBox">
          <div>众享商城</div>
          <div
            class="more"
            @click="$navTo('premiumMall', { activeIndex: '1' })"
          >
            查看更多<i class="el-icon-arrow-right" style="font-size: 16px"></i>
          </div>
        </div>

        <!-- 商品 -->
        <div class="goodsList">
          <div
            class="goodsDetail"
            v-for="(item, index) in year_goods"
            :key="'gnyamm' + index"
            v-show="index < 8"
            @click="
              $navTo('shopDetail', {
                id: item.id,
                merchant_id: item.merchant_message_id,
              })
            "
          >
            <!-- <el-image
              style="width: 200px; height: 200px"
              :src="$showImg(item.good_img)"
              fit="scale - down"
            ></el-image> -->
            <div class="imgsBox">
              <img :src="$showImg(item.good_img)" alt="加载失败" />
            </div>
            <div class="goodsName">{{ item.good_name }}</div>
            <div class="shopName">{{ item.name }}</div>
            <div class="goodsPrice">
              <span>￥</span>{{ item.activity_price }}
              <span class="good_price">{{ "￥" + item.good_price }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 探店  -->
      <div class="information">
        <div class="informationTitleBox">
          <div>探店</div>
          <div class="more" @click="$navTo('exploreShop')">
            查看更多<i class="el-icon-arrow-right" style="font-size: 16px"></i>
          </div>
        </div>

        <!-- 商品 -->
        <div class="goodsList" style="justify-content: space-between">
          <div
            class="goodsDetail tdBox"
            v-for="(item, index) in exploreShopList"
            :key="'gnyamm' + index"
            v-show="index < 2"
            @click="$navTo('exploreShop')"
          >
            <!-- <el-image
              style="width: 400px; height: 280px"
              :src="$showImg(item.img)"
              fit="scale - down"
            ></el-image> -->
            <div class="tdImgBox">
              <img :src="$showImg(item.img)" alt="加载失败" />
            </div>
            <div class="goodsName">{{ item.describe }}</div>
            <div class="shopName">{{ item.shop_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <i class="el-icon-top goUp" v-if="scroTop" @click="handleGoUp()"></i>
    <pageFoot></pageFoot>
  </div>
</template>

<script>
import pageFoot from "../components/pageFoot.vue";
export default {
  components: {
    pageFoot,
  },
  data() {
    return {
      alertsList: [],
      couponList: [],
      year_goods: [],
      exploreShopList: [],
      scroTop: false,
    };
  },
  beforeMount() {
    this.handleInformation();
    this.handleNewIndexList();
    this.handleYear_goods();
    this.getExploreShop();
    window.addEventListener("scroll", this.handleScroll);
  },

  activated() {
    console.log("我这个页面显示就会执行");
  },
  deactivated: function () {
    console.log("我这个页面退出的会执行");
  },
  beforeDestroy() {
    console.log("要被销毁了");
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleNav(url, bool) {
      if (bool) {
        this.$router.push(url);
      } else {
        window.open(url);
      }
    },

    //获取快讯
    handleInformation() {
      // 传得参数对象
      let query = {
        page: 1,
        type: 2,
        number: 5,
      };
      // 请求刚刚引入的封装的接口
      this.$api.information
        .changeInformation(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              if (item.img == null) {
                item.img = "";
              } else {
                item.img = item.img[0];
              }
              this.alertsList.push(item);
              console.log(this.$showImg(item.img));
            });
            console.log(this.alertsList);
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取超值购商品
    handleNewIndexList() {
      // 传得参数对象
      let query = {
        page: 1,
        number: 4,
        classify: 20002,
      };
      // 请求刚刚引入的封装的接口
      this.$api.coupon
        .NewIndexList(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.couponList = res.data.data.list;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取众享商城商品
    handleYear_goods() {
      // 传得参数对象
      let query = {
        page: 1,
      };
      // 请求刚刚引入的封装的接口
      this.$api.Good.year_goods(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.year_goods = res.data.data.year_goods;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取探店商品
    getExploreShop() {
      // 传得参数对象
      let query = {
        page: 1,
      };
      // 请求刚刚引入的封装的接口
      this.$api.merchant
        .getExploreShop(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.exploreShopList = res.data.data;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },
    //回到顶部
    handleGoUp() {
      this.scroTop = false;
      let Timer = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(Timer);
          return;
        } else {
          document.documentElement.scrollTop = parseInt(
            document.documentElement.scrollTop -
              document.documentElement.scrollTop / 5
          );
        }
      }, 20);
    },
    //监听高度变化
    handleScroll() {
      // console.log(window.pageYOffset);
      let sTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (sTop > 500) {
        this.scroTop = true;
      } else {
        this.scroTop = false;
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
.hello {
  width: 100vw;
  background-color: #ffffff;
}

.titleBox {
  width: 100vw;
  height: calc(100vw * 0.4942708333333333);
  padding: 34px 56px 34px 0;
  box-sizing: border-box;
  background: url(../assets/image/gn.jpg) 0 0 / 100% 100% no-repeat;
  position: relative;
  background-size: contain;
  box-sizing: border-box;
}

.login {
  width: 100%;
  height: 68px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 24px;
}

.login img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.Fun {
  width: 960px;
  height: 180px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: space-between;
}

.FunDetail {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
}

.FunDetail img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  background-size: contain;
}

.FunDetail div {
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.newMain {
  /* height: 1000px; */
  width: 960px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: aquamarine;
}

.newMain {
  width: 100%;
  padding-top: 30px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.information {
  width: 960px;
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  margin-bottom: 25px;
}

.informationTitleBox {
  width: 100%;
  height: 80px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #b5b5b5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.informationTitleBox div:first-of-type {
  height: 80px;
  line-height: 80px;
  box-sizing: border-box;
  border-bottom: 2px solid #e44a20;
  font-size: 22px;
  font-weight: 700;
  color: #000;
}

.informationTitleBox .more {
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  color: #000;
}

.newDetailBox {
  height: 380px;
  width: 100%;

  padding: 28px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.el-carousel {
  height: 380px;
  width: 460px;
}

.el-carousel__item .newImg {
  background-color: aqua;
  width: 460px;
  height: 320px;
  box-sizing: border-box;
  position: relative;
}

.el-carousel__item .PMTitleBox {
  width: 460px;
  height: 70px;
  padding: 0 24px;
  box-sizing: border-box;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.zxTitle {
  width: 360px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  margin-left: 6px;
}

.newList {
  width: 400px;
  height: 340px;
  box-sizing: border-box;
  background-color: #fff;
  list-style: inside;
}

.newList li {
  height: 64px;
  line-height: 64px;
  width: 100%;
  font-size: 14px;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.goodsList {
  /* height: 386px; */
  padding: 34px 38px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.goodsDetail {
  width: 200px;
  height: 300px;
  margin: 0 26px 20px 0;
}

.imgsBox {
  width: 200px;
  height: 200px;
}

.imgsBox img {
  width: 100%;
  height: 100%;
}

.tdImgBox {
  width: 450px;
  height: 272px;
}

.tdBox {
  width: 400px;
  padding-bottom: 10px;
}
.tdImgBox img {
  width: 100%;
  height: 100%;
}

.goodsDetail:nth-of-type(4n) {
  margin: 0;
}

.goodsName {
  height: 24px;
  margin-top: 10px;
  line-height: 24px;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.shopName {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #313131;
  font-size: 14px;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.goodsPrice {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #df0101;
  font-size: 16px;
}

.goodsPrice span {
  font-size: 14px;
}

.good_price {
  text-decoration: line-through;
  color: #b8b8b8;
}

/* 秒杀等 */

.imgsBox {
  width: 960px;
  /* border: 1px solid #b5b5b5; */
  /* background-color: #ffffff; */
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.imgsBox img {
  width: 220px;
  height: 168px;
}

.goUp {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 100px;
}
</style>
