<template>
  <div>
    <navHead activeId="3"></navHead>
    <div style="width: 100%">
      <div class="goodsBox">
        <div class="goodsInfo">
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(goodsDetail.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="thisimgBox">
            <img :src="$showImg(goodsDetail.good_img)" alt="" />
          </div>
          <div class="goodsInfoDetail">
            <div class="goodsName">{{ goodsDetail.good_name }}</div>
            <div class="spshuomin" v-if="goodsDetail.good_introduce">
              {{ goodsDetail.good_introduce }}
            </div>
            <div class="storeName">
              {{ goodsDetail.name }}
            </div>
            <!-- <div class="price"><span>￥</span>{{ goodsDetail.good_price }}</div> -->
            <div class="price" v-show="goodsDetail.good_price">
              ￥{{
                goodsDetail.advance_price * 1 > 0
                  ? goodsDetail.advance_price
                  : goodsDetail.activity_price
              }}<span class="unitTex"
                >元{{
                  goodsDetail.good_price_unit != "元"
                    ? "/" + goodsDetail.good_price_unit
                    : ""
                }}</span
              >
              <span class="yuan"
                >￥{{ goodsDetail.good_price }}元{{
                  goodsDetail.good_price_unit != "元"
                    ? "/" + goodsDetail.good_price_unit
                    : ""
                }}</span
              >
            </div>
            <!-- <div class="imgBox">
              <img
                width="60px"
                height="60px"
                v-for="(item, index) in goodsDetail.swiper_img"
                :key="'sgnusfgy' + index"
                v-show="index < 3"
                :src="$showImg(item)"
                alt=""
              />
            </div> -->
          </div>
        </div>

        <div class="goods">
          <!-- 店铺其他商品 -->
          <div class="storOtherGoods">
            <div class="thePoint">本店商品</div>
            <div
              class="otherGoodsDetail"
              v-for="(item, index) in merchantList"
              :key="'gayzem' + index"
              @click="goTo(item.id, merchant_id)"
            >
              <!-- <el-image
                style="width: 200px; height: 200px"
                :src="$showImg(item.good_img)"
                fit="scale - down"
              ></el-image> -->
              <div class="otherImgBox">
                <img :src="$showImg(item.good_img)" alt="" />
              </div>
              <div style="font-weight: 700">{{ item.good_name }}</div>
              <!-- <div>{{ item.merchant.merchant_name }}</div> -->
              <div
                class="price1"
                v-show="goodsDetail.good_price"
              >
                ￥{{
                  item.advance_price * 1 > 0
                    ? item.advance_price
                    : item.activity_price
                }}<span class="unitTex"
                  >元{{
                    item.good_price_unit != "元"
                      ? "/" + item.good_price_unit
                      : ""
                  }}</span
                >
                <span class="yuan"
                  >￥{{ item.good_price }}元{{
                    item.good_price_unit != "元"
                      ? "/" + item.good_price_unit
                      : ""
                  }}</span
                >
              </div>
            </div>
          </div>

          <!-- 当前商品详情 -->
          <div class="goodsDetail">
            <div class="goodsDetailTitle">商品详情</div>
            <!-- 图文富文本 -->
            <div class="detail" v-html="goodsDetail.good_details"></div>

            <!-- 规格 -->
            <div>
              <el-table :data="tableData" stripe style="width: 680px" border>
                <el-table-column prop="key" label="日期" width="219">
                </el-table-column>
                <el-table-column prop="value" label="姓名" width="438">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <i class="el-icon-top goUp" v-if="scroTop" @click="handleGoUp()"></i>
  </div>
</template>

<script>
import navHead from "../components/navHead.vue";
export default {
  components: {
    navHead,
  },
  data() {
    return {
      goodsDetail: {},
      id: "", //商品id
      merchant_id: "", //店铺id
      merchantList: [],
      tableData: [],
      scroTop: false,
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.merchant_id = this.$route.query.merchant_id;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getGoodFind();
    this.goodsList();
  },

  beforeDestroy() {
    console.log("要被销毁了");
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    //商品详情
    getGoodFind() {
      this.$api.good
        .getGoodFind({ id: this.id })
        .then((res) => {
          // 成功之后的操作
          console.log(res);

          this.goodsDetail = res.data.data;
          console.log(this.goodsDetail);

          this.tableData = [
            {
              key: "净含量",
              value: res.data.data.net_content || "/",
            },
            {
              key: "保质期",
              value: res.data.data.quality_time || "/",
            },
            {
              key: "生产许可证",
              value: res.data.data.production_license || "/",
            },
            {
              key: "产品标准号",
              value: res.data.data.product_number || "/",
            },
            {
              key: "类别",
              value: res.data.data.category || "/",
            },
            {
              key: "包装",
              value: res.data.data.packing || "/",
            },
            {
              key: "规格",
              value: res.data.data.specs || "/",
            },
          ];

          // this.MerchantList(this.goodsDetail.merchant.id);
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //商品列表
    goodsList() {
      this.$api.good
        .goodsList({ merchant_id: this.merchant_id })
        .then((res) => {
          // 成功之后的操作
          console.log(res);

          this.merchantList = res.data.data;
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    goTo(id, merchant_id) {
      console.log(this.$router);

      let routeUrl = this.router.resolve({
        name: "shopDetail",
        query: { id: id, merchant_id: merchant_id },
      });
      // console.log(id)
      window.open(routeUrl.href, "_blank");
    },

    //回到顶部
    handleGoUp() {
      this.scroTop = false;
      let Timer = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(Timer);
          return;
        } else {
          document.documentElement.scrollTop = parseInt(
            document.documentElement.scrollTop -
              document.documentElement.scrollTop / 5
          );
        }
      }, 20);
    },
    //监听高度变化
    handleScroll() {
      // console.log(window.pageYOffset);
      let sTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (sTop > 500) {
        this.scroTop = true;
      } else {
        this.scroTop = false;
      }
    },
  },
};
</script>

<style scoped>
.goodsBox {
  width: 960px;
  height: 1000px;
  padding-top: 30px;
  position: relative;
  margin: 0 auto;
}

.goodsInfo {
  height: 280px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.thisimgBox {
  width: 220px;
  height: 220px;
}

.thisimgBox img {
  width: 100%;
  height: 100%;
}

.goodsInfoDetail {
  width: 650px;
}

.goodsName {
  font-size: 24px;
  line-height: 42px;
  height: 84px;
  overflow: hidden;
  font-weight: 700;
}

.spshuomin {
  font-size: 20px;
  line-height: 30px;
  height: 120px;
  overflow: hidden;
}

.storeName {
  font-size: 20px;
  line-height: 36px;
  color: #313131;
}

.otherImgBox {
  width: 200px;
  height: 200px;
}

.otherImgBox img {
  width: 100%;
  height: 100%;
}

.price {
  font-size: 24px;
  line-height: 40px;
  color: #df0101;
}

.price1 {
  line-height: 40px;
  color: #df0101;
  font-size: 12px;
}

.yuan {
  text-decoration: line-through;
  margin-left: 10px;
}

.imgBox {
  height: 60px;
  display: flex;
}

.imgBox img {
  margin-right: 10px;
}

.goods {
  width: 960px;
  position: relative;
  margin: 30px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.storOtherGoods {
  width: 202px;
  /* background: brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.thePoint {
  width: 100%;
  font-size: 18px;
  color: #313133;
  text-align: center;
  line-height: 48px;
}

.otherGoodsDetail {
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  color: #313131;
  box-sizing: border-box;
}

.otherGoodsDetail div {
  line-height: 1.5em;
}

.goodsDetail {
  width: 720px;
  padding: 10px 30px 20px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.goodsDetailTitle {
  width: 100%;
  height: 56px;
  text-align: center;
  line-height: 56px;
  color: #313131;
  font-family: "黑体";
}

.detail {
  width: 658px;
}

.detail >>> img {
  width: 658px !important;
}

.goUp {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 100px;
}
</style>