import instance from '../http'

let api = {
    information: {//快讯

        //快讯列表
        //@ApiParams number  [string,number] 分页条数，可不传
        //@ApiParams page  [string,number] 当前分页
        //@ApiParams type  [string,number] 1,2  1为同城,2为快讯

        changeInformation(query) {
            return instance({
                url: '/information/changeInformation',
                method: 'post',
                data: query
            })
        },

        //快讯列表
        //@ApiParams id  [string] 文章id
        //@ApiParams status  [string,number] 1
        informationDetail(query) {
            return instance({
                url: '/information/informationDetail',
                method: 'post',
                data: query
            })
        },
    },

    ad: {//广告
        //广告
        //@ApiParams type [string,number] 广告类型
        AdList(query) {
            return instance({
                url: '/ad/AdList',
                method: 'post',
                data: query
            })
        },
    },

    coupon: {//超值购

        //超值购列表
        //@ApiParams number  [string,number] 分页条数，可不传
        //@ApiParams page  [string,number] 当前分页
        //@ApiParams classify  [string,number] 分类id
        //@ApiParams coupon_title  [string] 搜索关键词

        NewIndexList(query) {
            return instance({
                url: '/coupon/NewIndexList',
                method: 'post',
                data: query
            })
        },

        //超值购优惠券详情
        //@ApiParams  coupon_id [string,number] 优惠券id
        detail(query) {
            return instance({
                url: '/coupon/detail',
                method: 'post',
                data: query
            })
        },


        //超值购优惠券详情
        //@ApiParams  merchant_id [string,number] 店铺id
        MerchantList(query) {
            return instance({
                url: '/coupon/MerchantList',
                method: 'post',
                data: query
            })
        },


    },

    // 众享商城
    Good: {

        //众享商城列表
        //@ApiParams  page [string,number] 分页
        //@ApiParams  keywords [string] 搜索关键字
        year_goods(query) {
            return instance({
                url: '/Good/year_goods',
                method: 'post',
                data: query
            })
        },
        //众享商城列表
        //@ApiParams  page [string,number] 分页
        //@ApiParams  keywords [string] 搜索关键字
        //@ApiParams  id [string] id
        goods_classify(query) {
            return instance({
                url: '/Good/goods_classify',
                method: 'post',
                data: query
            })
        },

        //精品好货，团购，分销列表
        //@ApiParams  page [string,number] 分页
        //@ApiParams  keywords [string] 搜索关键字
        //@ApiParams  goods_type [string] 4,精品好货；5，团购，3，分销
        goods_type(query) {
            return instance({
                url: '/Good/goods_type',
                method: 'post',
                data: query
            })
        },



        //秒杀商品列表

        //@ApiParams  page [string,number] 分页
        //@ApiParams  keywords [string] 搜索关键字
        //@ApiParams  now_time [string] 当前时间
        get_kill_goodList(query) {
            return instance({
                url: '/Good/get_kill_goodList',
                method: 'post',
                data: query
            })
        },
    },

    // 店铺及探店
    merchant: {
        //众享商城列表
        //@ApiParams  page [string,number] 分页
        //@ApiParams  keywords [string] 搜索关键字
        getExploreShop(query) {
            return instance({
                url: '/merchant/getExploreShop',
                method: 'post',
                data: query
            })
        },
    },

    good: {
        //商品详情
        //@ApiParams  id [string,number] 商品id
        getGoodFind(query) {
            return instance({
                url: '/good/getGoodFind',
                method: 'post',
                data: query
            })
        },
        //店铺商品列表
        //@ApiParams  merchant_id [string,number] 店铺id
        goodsList(query) {
            return instance({
                url: '/good/goodsList',
                method: 'post',
                data: query
            })
        },
    }


}

export default api

