<template>
  <div class="guolong">
    <div class="login">
      <img src="../assets/image/login.png" alt="" />
      <div
        style="line-height: 80px"
        @click="
          handleNav(
            'https://sh.egzss.com/admin/login/index',
            false
          )
        "
      >
        登录/注册
      </div>
    </div>

    <div class="Fun">
      <div class="FunDetail">
        <img src="../assets/image/peisong.png" alt="" />
        <div>
          <div class="name">璧到家</div>
          <div>新鲜蔬菜配送到家</div>
        </div>
      </div>
      <div class="FunDetail">
        <img src="../assets/image/nongmao.png" alt="" />
        <div>
          <div class="name">农贸市场摊位管理</div>
          <div>摊位小贩管理</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guolong",
  methods: {
    handleNav(url, bool) {
      if (bool) {
        this.$router.push(url);
      } else {
        window.open(url);
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
.guolong {
  width: 100%;
  height: 100vh;
  padding: 34px 56px;
  box-sizing: border-box;
  background: url(../assets/image/gnNum.jpg) 0 0 / 100% 100% no-repeat;
}

.login {
  width: 100%;
  height: 98px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 32px;
}

.login img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.Fun {
  height: 510px;
  width: 486px;
  position: absolute;
  right: 216px;
  bottom: 132px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.FunDetail {
  height: 210px;
  width: 486px;
  padding: 48px 12px ;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  background-color: rgba(92, 114, 90, 0.5);
}

.FunDetail img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 40px;
}

.FunDetail > div {
  height: 120px;
  padding: 10px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 22px;
}

.FunDetail > div div{
  line-height: 50px;
}

.name{
  font-size: 32px;
}
</style>
