<template>
  <div>
    <!-- <navHead activeId="2"></navHead> -->
    <div class="detailBox">
      <div class="articleInfoBox">
        <div class="articleTitle">{{ information.title }}</div>
        <div class="articleInfo">
          <img width="50px" height="50px" src="@/assets/image/lsn.png" alt="" />
          <div class="infoBox">
            <div class="articleName">众享城市生活</div>
            <div class="timeBox">
              <div>发布时间：{{ $showTime(information.add_time) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="htmlBox" v-html="information.content"></div>
    </div>
  </div>
</template>

<script>
// import navHead from "../components/navHead.vue";
export default {
  components: {
    // navHead,
  },
  data() {
    return {
      id: "",
      information: {},
    };
  },
  created() {
    console.log(this.$route.params);
    this.id = this.$route.query.id;
  },
  beforeMount() {
    this.handleInformation();
  },
  methods: {
    handleInformation() {
      // 请求刚刚引入的封装的接口
      this.$api.information
        .informationDetail({ id: this.id, status: 1 })
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.information = res.data.data.information;
          } else {
            return;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },
  },
};
</script>
<style scoped>
.detailBox {
  width: 100%;
  position: relative;
}

.articleInfoBox {
  background-color: #f7f7f7;
}

.articleTitle {
  height: 108px;
  width: 1040px;
  line-height: 108px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  position: relative;
  margin: 0 auto;
}

.articleInfo {
  height: 50px;
  width: 1040px;
  position: relative;
  margin: 0 auto;

  display: flex;
}

.infoBox {
  margin-left: 8px;
}

.articleName {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
}

.timeBox {
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.articleSource {
  margin-left: 8px;
  padding-left: 8px;
  height: 14px;
  line-height: 14px;
  box-sizing: border-box;
  border-left: 1px solid #3c3c3c;
}

.htmlBox {
  width: 960px;
  position: relative;
  margin: 0 auto;
  padding-top: 54px;
}
</style>