<template>
  <div>
    <navHead activeId="4"></navHead>
    <div class="exploreShopBox">
      <div
        class="exploreDetail"
        v-for="(item, index) in exploreShopList"
        :key="'ingmshjg' + index"
      >
        <div class="imgBox">
          <!-- <img class="titleImg" :src="$showImg(item.img)" alt="" /> -->
          <video
            :ref="'video' + index"
            class="titleImg"
            :src="$showImg(item.video)"
            :controls="item.paly"
            @click="hanflePaly(index, item.paly)"
          ></video>
          <img
            v-if="!item.paly"
            @click="hanflePaly(index, item.paly)"
            class="palyImg"
            src="@/assets/image/paly.png"
            alt=""
          />
          <div class="imgInfo" v-if="!item.paly">
            <div class="shop_name">{{ item.shop_name }}</div>
            <div class="describe">
              <img src="@/assets/image/shop.png" alt="" />
              <div>{{ item.describe }}</div>
            </div>
          </div>
        </div>

        <div class="packageBox" v-if="item.good.length > 0">
          <div
            class="package"
            v-for="(itm, idx) in item.good"
            :key="'ugnsmfhm' + idx"
          >
            <div>{{ itm.name }}</div>
            <div class="price">￥{{ itm.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navHead from "../components/navHead.vue";
export default {
  components: {
    navHead,
  },
  data() {
    return {
      exploreShopList: [],
    };
  },
  created() {
    this.getExploreShop();
  },
  methods: {
    //获取探店商品
    getExploreShop() {
      // 传得参数对象
      let query = {
        page: 1,
      };
      // 请求刚刚引入的封装的接口
      this.$api.merchant
        .getExploreShop(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              item.paly = false;
              this.exploreShopList.push(item);
            });
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    hanflePaly(index, paly) {
      let $this = this;
      console.log(paly);
      this.exploreShopList.forEach((item, idx) => {
        let vPlay = "video" + idx;
        if (idx == index) {
          item.paly = !paly;
          if (paly) {
            $this.$refs[vPlay][0].pause();
          } else {
            $this.$refs[vPlay][0].play();
          }
        } else {
          item.paly = false;
          $this.$refs[vPlay][0].pause();
        }
      });
    },
  },
};
</script>
<style scoped>
.exploreShopBox {
  width: 960px;
  position: relative;
  margin: 10px auto;
}

.exploreDetail {
  width: 100%;
  margin-bottom: 20px;
}

.imgBox {
  position: relative;
}

.titleImg {
  width: 100%;
  height: 576px;
}

.palyImg {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgInfo {
  width: 100%;
  height: 100px;
  padding: 10px 30px;
  box-sizing: border-box;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}

.shop_name {
  height: 50px;
  line-height: 50px;
  font-size: 30px;
}

.describe {
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.describe img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.packageBox {
  width: 960px;
  height: 100px;
  padding: 20px 0;
  overflow-x: auto;
  box-sizing: border-box;
  display: flex;
}

.package {
  height: 100%;
  width: 320px;
  text-align: center;
  border-right: 2px solid #f00;
}

.package:last-of-type {
  border: none;
}

.package div {
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.price {
  color: #f00;
  font-size: 16px;
}
</style>