<template>
  <div>
    <div class="navHead">
      <div class="logo">
        <img width="50px" height="50px" src="@/assets/image/lsn.png" alt="" />
        <div>
          <div class="logoName">众享城市生活</div>
          <div style="font-size: 14px">CITY LIFE FOR ALL</div>
        </div>
      </div>
      <div :class="activeId == 1 ?'txtActive':''" @click="$navTo('HelloWorld')">首页</div>
      <div :class="activeId == 2 ?'txtActive':''" @click="$navTo('informationList')">最新资讯</div>
      <div :class="activeId == 3 ?'txtActive':''" @click="$navTo('premiumMall',{activeIndex:'1'})">众享商城</div>
      <div :class="activeId == 4 ?'txtActive':''" @click="$navTo('exploreShop')">探店</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "navHead",
  props: {
    activeId: {
      type: [Number, String],
      default: 1,
      
    },
  },
};
</script>
<style scoped>
.navHead {
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #3c3c3c;
  font-size: 16px;
  /* font-weight: 700; */
  font-family: "黑体";
  border-bottom: 2px solid #f0f0f0;
}

.txtActive {
  font-weight: 700;
  color: #e71b1b;
  border-bottom: 2px solid #e71b1b;
}

.logo {
  display: flex;
}

.logo img {
  margin-right: 12px;
}

.logoName {
  height: 32px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 700;
}
</style>