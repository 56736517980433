<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./assets/css/util.css";
#app {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  background-size: 100% 100%;
  overflow-x: hidden;
}
</style>
