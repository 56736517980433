import axios from 'axios'
import Vue from 'vue'
import store from '../store'
// import router from '../router'

axios.defaults.withCredentials = false //跨域写入cookie的配置

// store.state.host + 
const instance = axios.create({
  baseURL: store.state.host + '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})



// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  console.log('请求拦截')
  // 可以在发请求前判断用户状态
  // 可以在每一个请求的接口中统一添加【请求头】
  let token = localStorage.getItem('token');
  if (token) {
    config.headers.myToken = token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  console.log('响应拦截')
  let { code, msg } = response.data;
  let type = "info";
  if (code == -666) { type = "warning" }
  if (code == 0) {
    type = "error"
    Vue.prototype.$message({
      message: msg,
      type
    })
  }
  // if(code==1){ type="success" }


  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
export default instance