<template>
  <div>
    <navHead activeId="3"></navHead>

    <!-- 商城导航 -->
    <div class="mallBox">
      <div class="navBox">
        <!-- 导航栏 -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">众享商城</el-menu-item>
          <el-menu-item index="2">超值购</el-menu-item>
          <el-menu-item index="3">秒杀</el-menu-item>
          <el-menu-item index="4">精品好货</el-menu-item>
          <el-menu-item index="5">团购</el-menu-item>
          <el-menu-item index="6">分销有利</el-menu-item>
        </el-menu>
      </div>

      <!-- 商品分类 -->
      <div class="goodsClassification" :style="{ backgroundColor: backColor }">
        <div
          class="classificationImgBox"
          :style="{
            background: 'url(' + backImage + ') 0 0 / 100% 100% no-repeat',
          }"
        >
          <!-- 顶部搜索 -->
          <div class="searchBox">
            <div class="classification" v-if="activeIndex <= 2">分类</div>
            <div class="search" v-if="activeIndex != 1">
              <i class="el-icon-search"></i>
              <input
                type="text"
                v-model="keyWord"
                placeholder="搜索"
                @keyup.enter="handleEnter()"
              />
            </div>
          </div>

          <div
            class="classificationBox"
            v-if="activeIndex == 1 || activeIndex == 2"
          >
            <!-- 左边分类 -->
            <div class="classificationList">
              <div
                class="classification"
                v-for="(item, index) in adList"
                :key="'azaejsrcanj' + index"
                :class="item.isActive ? 'isActive' : ''"
                @click="handleChage(index, item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 众享商城 -->
    <div class="goodsListBox" v-if="activeIndex == 1">
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in classifyDetail"
          :key="'gnyamm' + index"
          @click="
            $navTo('shopDetail', {
              id: item.id,
              merchant_id: item.merchant_message_id,
            })
          "
        >
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.good_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.good_name }}</div>
          <div class="shopName">{{ item.brand }}</div>
          <div class="goodsPrice">
            <span style="font-size: 14px">活动价：￥</span
            >{{ item.activity_price }}
          </div>
        </div>
      </div>
      <!-- 每日推荐 -->
      <div>
        <div class="yearTitle">每日推荐</div>
        <div class="gondsList">
          <div
            class="goodsDetail"
            v-for="(item, index) in jingxuan"
            :key="'gnyamm' + index"
            @click="
              $navTo('shopDetail', {
                id: item.id,
                merchant_id: item.merchant_message_id,
              })
            "
          >
            <!-- <el-image
              style="width: 220px; height: 220px"
              :src="$showImg(item.good_img)"
              fit="scale - down"
            ></el-image> -->
            <div class="imgsBox">
              <img :src="$showImg(item.good_img)" alt="" />
            </div>
            <div class="goodsName">{{ item.good_name }}</div>
            <div class="shopName">{{ item.brand }}</div>
            <div class="goodsPrice">
              <span style="font-size: 14px">活动价：￥</span
              >{{ item.activity_price }}
            </div>
          </div>
        </div>
      </div>
      <!-- 精品好货 -->
      <div>
        <div class="yearTitle">精品好货</div>
        <div class="gondsList">
          <div
            class="goodsDetail"
            v-for="(item, index) in year_goods"
            :key="'gnyamm' + index"
            @click="
              $navTo('shopDetail', {
                id: item.id,
                merchant_id: item.merchant_message_id,
              })
            "
          >
            <!-- <el-image
              style="width: 220px; height: 220px"
              :src="$showImg(item.good_img)"
              fit="scale - down"
            ></el-image> -->
            <div class="imgsBox">
              <img :src="$showImg(item.good_img)" alt="" />
            </div>
            <div class="goodsName">{{ item.good_name }}</div>
            <div class="shopName">{{ item.brand }}</div>
            <div class="goodsPrice">
              <span style="font-size: 14px">活动价：￥</span
              >{{ item.activity_price }}
            </div>
          </div>
          <div class="noGoods" v-if="isAll1">已经到底了</div>
        </div>
      </div>
    </div>

    <!-- 超值购商品列表 -->
    <div class="goodsListBox" v-if="activeIndex == 2">
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in couponList"
          :key="'gnyamm' + index"
          @click="$navTo('goodsDetail', { id: item.id })"
        >
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.coupon_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.coupon_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.coupon_title }}</div>
          <div class="shopName">{{ item.merchant.merchant_name }}</div>
          <div class="goodsPrice">
            <span style="font-size: 14px">售价：￥</span>{{ item.price }}
          </div>
        </div>

        <div class="noGoods" v-if="isAll2">已经到底了</div>
      </div>
    </div>

    <!-- 秒杀 -->
    <div class="goodsListBox" v-if="activeIndex == 3">
      <div class="killTime">
        <div class="killTitle">今日秒杀</div>
        <div class="txt2">
          {{ killHour }}:{{ killMinutes }}:{{ killSeconds }}
        </div>
      </div>
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in killgoods"
          :key="'gnyamm' + index"
        >
          <!-- @click="$navTo('goodsDetail', { id: item.id })" -->
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.good_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.good_name }}</div>
          <div class="killYuanprice">
            活动价：￥{{ item.activity_price }}
            <span>原价：￥{{ item.good_price }}</span>
          </div>
          <div class="goodsPrice">
            <span style="font-size: 14px">秒杀价：￥</span>{{ item.kill_price }}
          </div>
        </div>

        <div class="noGoods" v-if="isAll3">已经到底了</div>
      </div>
    </div>

    <!-- 精品好货 -->
    <div class="goodsListBox" v-if="activeIndex == 4">
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in JPList"
          :key="'gnyamm' + index"
          @click="
            $navTo('shopDetail', {
              id: item.id,
              merchant_id: item.merchant_message_id,
            })
          "
        >
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.good_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.good_name }}</div>
          <div class="shopName">{{ item.name }}</div>
          <div class="activity_price">
            <div><span>活动价：￥</span>{{ item.activity_price }}</div>
            <div><span>原价：￥</span>{{ item.good_price }}</div>
          </div>
        </div>
        <div class="noGoods" v-if="isAll4">已经到底了</div>
      </div>
    </div>

    <!-- 团购 -->
    <div class="goodsListBox" v-if="activeIndex == 5">
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in TGList"
          :key="'gnyamm' + index"
          @click="
            $navTo('shopDetail', {
              id: item.id,
              merchant_id: item.merchant_message_id,
            })
          "
        >
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.good_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.good_name }}</div>
          <div class="shopName">{{ item.name }}</div>
          <div class="activity_price">
            <div><span>活动价：￥</span>{{ item.activity_price }}</div>
            <div><span>原价：￥</span>{{ item.good_price }}</div>
          </div>
        </div>
        <div class="noGoods" v-if="isAll5">已经到底了</div>
      </div>
    </div>

    <!-- 分销 -->
    <div class="goodsListBox" v-if="activeIndex == 6">
      <div class="gondsList">
        <div
          class="goodsDetail"
          v-for="(item, index) in FXList"
          :key="'gnyamm' + index"
          @click="
            $navTo('shopDetail', {
              id: item.id,
              merchant_id: item.merchant_message_id,
            })
          "
        >
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(item.good_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="imgsBox">
            <img :src="$showImg(item.good_img)" alt="" />
          </div>
          <div class="goodsName">{{ item.good_name }}</div>
          <div class="shopName">{{ item.name }}</div>
          <div class="activity_price">
            <div><span>活动价：￥</span>{{ item.activity_price }}</div>
            <div><span>原价：￥</span>{{ item.good_price }}</div>
          </div>
        </div>
        <div class="noGoods" v-if="isAll6">已经到底了</div>
      </div>
    </div>
    <i class="el-icon-top goUp" v-if="scroTop" @click="handleGoUp()"></i>
  </div>
</template>

<script>
import navHead from "../components/navHead.vue";
let Timer;
let $this;
let t;
export default {
  components: {
    navHead,
  },
  data() {
    return {
      activeIndex: "1",
      backImage: "",
      backColor: "",
      adList: [],
      adImgList: [],
      keyWord: "", //搜索关键字
      couponList: [], //优惠券列表
      couponClassify: [], //超值购分类
      classify: [], //众享商城分类
      classifyDetail: [], //众享商城分类详情列表
      year_goods: [], //每日推荐
      jingxuan: [], //众享商城精品好货
      killgoods: [], //秒杀商品
      JPList: [], //精品好货
      TGList: [], //团购
      FXList: [], //分销
      page1: 1,
      page2: 1,
      page3: 1,
      page4: 1,
      page5: 1,
      page6: 1,
      isAll1: false, //众享商城
      isAll2: false, //超值购
      isAll3: false, //秒杀
      isAll4: false, //精品好货
      isAll5: false, //团购
      isAll6: false, //秒杀
      ico: "",
      scroTop: false,
      killHour: 0, //时
      killMinutes: 0, //分
      killSeconds: 0, //秒
    };
  },

  created() {
    $this = this;
    console.log(this.$route.query);
    if (this.$route.query.activeIndex) {
      this.activeIndex = this.$route.query.activeIndex;
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.Debounce(this.handleScroll, 500));
    //
    this.handleYear_goods();
    //超值购分类列表
    this.AdList(9);
    //广告图
    this.AdList(32);

    //秒杀
    this.get_kill_goodList();

    //精品好礼
    this.goods_type(4, this.page4);
    this.goods_type(5, this.page5);
    this.goods_type(3, this.page6);
  },

  activated() {
    console.log("我这个页面显示就会执行");
  },
  deactivated: function () {
    console.log("我这个页面退出的会执行");
    clearInterval(Timer);
  },

  beforeDestroy() {
    console.log("要被销毁了");
    window.removeEventListener("scroll", this.Debounce(this.handleScroll, 500));
  },

  methods: {
    // 切换商城
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key;
      this.keyWord = "";
      // this.AdList(32);
      if ($this.adImgList.length >= $this.activeIndex) {
        $this.backImage = $this.adImgList[$this.activeIndex * 1 - 1].image;
        $this.backColor =
          $this.adImgList[$this.activeIndex * 1 - 1].description;
      }
      if (this.activeIndex == 1) {
        this.adList = [...this.classify];
      }
      if (this.activeIndex == 2) {
        this.adList = [...this.couponClassify];
      }
    },

    //获取超值购分类列表
    AdList(type) {
      // 请求刚刚引入的封装的接口
      this.$api.ad
        .AdList({ type })
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            if (type == 9) {
              res.data.data.forEach((item) => {
                item.isActive = false;
                this.couponClassify.push(item);
              });
              if (this.couponClassify.length) {
                this.couponClassify[0].isActive = true;
                this.handleNewIndexList(this.couponClassify[0].ico);
              }
              if (this.activeIndex == 2) {
                this.adList = [...this.couponClassify];
              }
            }
            if (type == 32) {
              console.log($this.activeIndex);

              res.data.data.forEach((item) => {
                item.image = $this.$showImg(item.image);
                this.adImgList.push(item);
              });

              $this.backImage =
                $this.adImgList[$this.activeIndex * 1 - 1].image;
              $this.backColor =
                $this.adImgList[$this.activeIndex * 1 - 1].description;
            }
          } else {
            return;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //众享商城、超值购分类切换
    handleChage(index, item) {
      this.adList.forEach((item) => {
        item.isActive = false;
      });
      this.adList[index].isActive = true;
      this.page = 1;
      this.isAll = false;
      if (this.activeIndex == 1) {
        this.goods_classify(this.classify[index].id);
      }

      if (this.activeIndex == 2) {
        this.ico = item.ico;
        this.couponList = [];
        this.handleNewIndexList(item.ico);
      }
    },

    //获取超值购商品
    handleNewIndexList(ico) {
      // 传得参数对象
      let query = {
        page: this.page2,
        number: 16,
        classify: ico,
        coupon_title: this.keyWord,
      };
      // 请求刚刚引入的封装的接口
      this.$api.coupon
        .NewIndexList(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.list.forEach((item) => {
              this.couponList.push(item);
            });
            if (res.data.data.list.length < 16) {
              this.isAll2 = true;
            }
          } else {
            this.isAll2 = true;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取众享商城商品
    handleYear_goods() {
      // 传得参数对象
      let query = {
        page: this.page1,
      };
      // 请求刚刚引入的封装的接口
      this.$api.Good.year_goods(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            // this.year_goods = res.data.data.year_goods;
            res.data.data.year_goods.forEach((item) => {
              this.year_goods.push(item);
            });
            if (res.data.data.year_goods.length < 15) {
              this.isAll1 = true;
            }
            this.jingxuan = res.data.data.jingxuan;
            this.classify = res.data.data.classify;
            if (this.activeIndex == 1) {
              this.adList = [...this.classify];
              this.goods_classify(this.classify[0].id);
            }
          } else {
            this.isAll1 = true;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取众享商城分类商品
    goods_classify(id) {
      // 传得参数对象
      let query = {
        page: 1,
        id: id,
        number: 100,
      };
      // 请求刚刚引入的封装的接口
      this.$api.Good.goods_classify(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.classifyDetail = res.data.data;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //精品好货，团购，分销
    goods_type(goods_type, page) {
      // 传得参数对象
      let query = {
        page: page,
        keywords: this.keyWord,
        goods_type: goods_type,
      };
      // 请求刚刚引入的封装的接口
      this.$api.Good.goods_type(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              if (goods_type == 4) {
                this.JPList.push(item);
              }

              if (goods_type == 5) {
                this.TGList.push(item);
              }

              if (goods_type == 3) {
                this.FXList.push(item);
              }
            });

            if (res.data.data.length < 15) {
              if (goods_type == 4) {
                this.isAll4 = true;
              }

              if (goods_type == 5) {
                this.isAll5 = true;
              }

              if (goods_type == 3) {
                this.isAll6 = true;
              }
            }
          } else {
            if (goods_type == 4) {
              this.isAll4 = true;
            }

            if (goods_type == 5) {
              this.isAll5 = true;
            }

            if (goods_type == 3) {
              this.isAll6 = true;
            }
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //获取众享商城分类商品
    get_kill_goodList() {
      let now_time = new Date().getTime() / 1000;

      // 传得参数对象
      let query = {
        page: this.page3,
        keywords: this.keyWord,
        now_time: parseInt(now_time),
      };
      // 请求刚刚引入的封装的接口
      this.$api.Good.get_kill_goodList(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.result.forEach((item) => {
              this.killgoods.push(item);
            });
            if (res.data.data.result.length < 15) {
              this.isAll3 = true;
            }

            if (res.data.data.result.length > 0) {
              clearInterval(t);
              let timestamp = new Date().getTime();
              console.log(timestamp);
              // console.log(timer);
              let t2 =
                new Date(new Date().toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000;
              let timer = t2 - timestamp * 1;
              this.killHour = parseInt(
                (timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              if (this.killHour < 10) {
                this.killHour = "0" + this.killHour;
              }
              this.killMinutes = parseInt(
                (timer % (1000 * 60 * 60)) / (1000 * 60)
              );
              this.killSeconds = parseInt((timer % (1000 * 60)) / 1000);
              // console.log(s);
              t = setInterval(() => {
                // clearInterval(t)
                if (this.killSeconds == 0) {
                  this.killSeconds = 59;
                  if (this.killMinutes == 0) {
                    this.killMinutes = 59;
                    this.killHour--;
                  } else {
                    this.killMinutes--;
                  }
                } else {
                  this.killSeconds--;
                }
                if (this.killHour == 0 && this.killMinutes == 0) {
                  // clearInterval(t)
                }
                // console.log(t);
              }, 1000);
            }
          } else {
            this.isAll3 = true;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //回车搜索
    handleEnter() {
      if (this.activeIndex == 2) {
        this.page2 = 1;
        this.isAll2 = false;
        this.couponList = [];
        this.handleNewIndexList(this.ico);
      }

      if (this.activeIndex == 3) {
        this.page3 = 1;
        this.isAll3 = false;
        this.killgoods = [];
        this.get_kill_goodList(this.ico);
      }
      if (this.activeIndex == 4) {
        this.page4 = 1;
        this.isAll4 = false;
        this.JPList = [];
        this.goods_type(4, this.page4);
      }
      if (this.activeIndex == 5) {
        this.page5 = 1;
        this.isAll5 = false;
        this.TGList = [];
        this.goods_type(5, this.page5);
      }
      if (this.activeIndex == 6) {
        this.page6 = 1;
        this.isAll6 = false;
        this.FXList = [];
        this.goods_type(3, this.page6);
      }
    },

    //回到顶部
    handleGoUp() {
      this.scroTop = false;
      let Timer = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(Timer);
          return;
        } else {
          document.documentElement.scrollTop = parseInt(
            document.documentElement.scrollTop -
              document.documentElement.scrollTop / 5
          );
        }
      }, 20);
    },

    //上拉加载
    handleScroll() {
      let sTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (sTop > 500) {
        this.scroTop = true;
      } else {
        this.scroTop = false;
      }
      let ScrollTop, ClientHeight, ScrollHeight;
      ScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      ClientHeight = document.documentElement.clientHeight;
      ScrollHeight = document.documentElement.scrollHeight;

      if (ScrollHeight - ScrollTop - ClientHeight < 200) {
        // console.log(111);

        if ($this.activeIndex == 1 && !$this.isAll1) {
          $this.page1 += 1;
          $this.handleYear_goods();
        }
        if ($this.activeIndex == 2 && !$this.isAll2) {
          $this.page2 += 1;
          $this.handleNewIndexList($this.ico);
        }
        if ($this.activeIndex == 3 && !$this.isAll3) {
          $this.page3 += 1;
          $this.get_kill_goodList();
        }
        if ($this.activeIndex == 4 && !$this.isAll4) {
          $this.page4 += 1;
          $this.goods_type(4, this.page4);
        }
        if ($this.activeIndex == 5 && !$this.isAll5) {
          $this.page5 += 1;
          $this.goods_type(5, this.page5);
        }
        if ($this.activeIndex == 6 && !$this.isAll6) {
          $this.page3 += 1;
          $this.goods_type(3, this.page6);
        }
      } else {
        // console.log(222);
      }
    },

    //防抖
    Debounce(fn, t) {
      let delay = t || 500;
      let timer;
      // console.log(fn);
      // console.log(typeof fn);
      return function () {
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, args);
        }, delay);
      };
    },
  },
};
</script>

<style scoped>
.mallBox {
  width: 100%;
}
.navBox {
  height: 60px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 4px;
}

.el-menu-demo {
  width: 1040px;
  height: 60px;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.el-menu-item {
  color: #3c3c3c !important;
  padding: 0 30px;
  font-size: 16px;
  box-sizing: border-box;
}
.is-active {
  color: #fe610d !important;
  font-weight: 700 !important;
  font-size: 16px;
  background-color: #fff;
  border-bottom-color: #ff542f !important;
}

.goodsClassification {
  width: 100%;
  height: 560px;
  background-color: #ff542f;
  overflow: hidden;
}

.classificationImgBox {
  height: 560px;
  width: 1040px;
  position: relative;
  margin: 0 auto;
  /* background: url(../assets/image/youhui.png) 0 0 / 100% 100% no-repeat; */
}

.searchBox {
  width: 100%;
  height: 56px;
  color: #fff;
  display: flex;
  align-items: center;
}

.classification {
  height: 56px;
  width: 160px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 6px;
  font-size: 16px;
}

.isActive {
  background-color: rgba(255, 255, 255, 0.3);
}

.search {
  height: 40px;
  width: 680px;
  padding: 8px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  color: #999;
}

.search input {
  margin-left: 4px;
  flex: 1;
  height: 24px;
  border: none;
  outline: none;
}

.classificationBox {
  width: 100%;
}

.classificationList {
  background-color: rgba(0, 0, 0, 0.3);
  width: 160px;
  color: #fff;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.goodsListBox {
  width: 100%;
  padding-top: 10px;
  position: relative;
}

.yearTitle {
  height: 40px;
  width: 160px;
  text-align: center;
  border-radius: 20px;
  background: #f26c4f;
  color: #fff;
  font: 700 20px/40px KaiTi;
  position: relative;
  margin: 10px auto;
}

.gondsList {
  width: 960px;
  /* height: 2000px; */
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.goodsDetail {
  width: 220px;
  height: 320px;
  margin: 0 26px 20px 0;
}

.goodsDetail:nth-of-type(4n) {
  margin: 0;
}

.imgsBox {
  width: 220px;
  height: 220px;
}

.imgsBox img {
  width: 100%;
  height: 100%;
}

.goodsName {
  height: 24px;
  margin-top: 10px;
  line-height: 24px;
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.shopName {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #313131;
  font-size: 14px;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.goodsPrice {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #df0101;
  font-size: 16px;
}

.noGoods {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.goUp {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 100px;
}

.killYuanprice {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #555;
  text-decoration: line-through;
}

.killYuanprice span {
  font-size: 14px;
  color: #b0b0b0;
}

.killTime {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.killTitle {
  color: #f00;
  font: 700 32px kaiti;
}

.txt2 {
  padding: 5px;
  font-size: 21px;
  font-weight: 700;
  color: #ffffff;
  background-color: #f26c4f;
}

.activity_price {
  width: 100%;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f00;
}

.activity_price div:last-of-type {
  margin-left: 6px;
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.activity_price span {
  font-size: 12px;
}
</style>