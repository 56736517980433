<template>
  <div>
    <navHead activeId="2"></navHead>
    <div class="navBox">
      <div class="newTitle">
        <div class="navName">最新资讯</div>
        <div class="EnglishInfo">LATEST NEWS</div>
      </div>
    </div>
    <!-- 资讯列表 -->
    <div class="infoList">
      <div
        class="infoDetail"
        v-for="(item, index) in alertsList"
        :key="'nhwmjql' + index"
        @click="$navTo('information', { id: item.id })"
      >
        <!-- <el-image
          style=""
          :src="$showImg(item.img)"
          fit="scale - down"
          ><div slot="error" class="image-slot noImg">暂无图片</div></el-image
        > -->

        <div class="imgBox" v-if="item.img">
          <img :src="$showImg(item.img)" alt="" />
        </div>
        
        <div v-else class="noImg">暂无图片</div>

        <div class="infoContent">
          <div class="infoName">{{ item.title }}</div>
          <div class="time">
            <div>{{ $showTime(item.add_time) }}</div>
            <div>{{ item.views }}阅读</div>
          </div>
        </div>
      </div>
    </div>

    <i class="el-icon-top goUp" v-if="scroTop" @click="handleGoUp()"></i>
  </div>
</template>

<script>
let $this;
import navHead from "../components/navHead.vue";
export default {
  components: {
    navHead,
  },
  data() {
    return {
      alertsList: [],
      isAll: false,
      page: 1,
      scroTop: false,
    };
  },
  beforeMount() {
    $this = this;
    this.handleInformation();
  },
  mounted() {
    window.addEventListener("scroll", this.Debounce(this.handleScroll, 500));
  },
  methods: {
    //获取快讯
    handleInformation() {
      // 传得参数对象
      let query = {
        page: this.page,
        type: 2,
        number: 10,
      };
      // 请求刚刚引入的封装的接口
      this.$api.information
        .changeInformation(query)
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              if (item.img == null) {
                item.img = "";
              } else {
                item.img = item.img[0];
              }
              this.alertsList.push(item);
              console.log(this.$showImg(item.img));
            });
            if (res.data.data.length < 10) {
              this.isAll = false;
            }
            console.log(this.alertsList);
          } else {
            this.isAll = true;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    handleScroll() {
      let sTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (sTop > 500) {
        this.scroTop = true;
      } else {
        this.scroTop = false;
      }
      if (this.isAll) {
        return;
      } else {
        let ScrollTop, ClientHeight, ScrollHeight;
        ScrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        ClientHeight = document.documentElement.clientHeight;
        ScrollHeight = document.documentElement.scrollHeight;

        if (ScrollHeight - ScrollTop - ClientHeight < 200) {
          console.log(111);
          $this.page += 1;
          $this.handleInformation();
        } else {
          console.log(222);
        }
      }
    },

    //回到顶部
    handleGoUp() {
      this.scroTop = false;
      let t = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(t);
          return;
        } else {
          document.documentElement.scrollTop = parseInt(
            document.documentElement.scrollTop -
              document.documentElement.scrollTop / 5
          );
        }
      }, 20);
    },

    //防抖
    Debounce(fn, t) {
      let delay = t || 500;
      let timer;
      // console.log(fn);
      // console.log(typeof fn);
      return function () {
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, args);
        }, delay);
      };
    },
  },
};
</script>

<style scoped>
.navBox {
  width: 100%;
  height: 200px;
  padding: 0 360px;
  box-sizing: border-box;
  background: url(../assets/image/newInfo.png) 0 0 / 100% 100% no-repeat;
  background-size: cover;
}

.newTitle {
  width: 360px;
  height: 154px;
  padding-top: 30px;
  margin-left: 60px;
  color: #fff;
  border-bottom: 2px solid #fff;
  box-sizing: border-box;
}

.navName {
  font-size: 46px;
  line-height: 1.5em;
  font-weight: 700;
  letter-spacing: 16px;
}

.EnglishInfo {
  font-size: 28px;
  line-height: 64px;
}

.infoList {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoDetail {
  height: 160px;
  width: 960px;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 3px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imgBox {
  width: 166px;
  height: 114px;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.noImg{
  width: 166px;
  height: 114px;
  line-height: 144px;
  text-align: center;
  font-size: 16px;
  color: #666;
}

.infoContent {
  width: 720px;
}

.infoName {
  height: 64px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.intro {
  height: 52px;
  line-height: 26px;
  font-size: 16px;
  color: #666;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.time {
  width: 260px;
  height: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #969696c7;
}

.goUp {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 100px;
}
</style>