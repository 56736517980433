import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host:'https://sh.egzss.com',//请求地址
    staticPath: 'https://image.egzss.com/',//图片地址
  },
  mutations: {

  },
  actions: {
  },
  modules: {
  }
})
