<template>
  <div class="pageFoot">
    <div class="foot">
      <div class="info">
        <div @click="handleClick()">网站首页</div>
        <div @click="$navTo('informationList')">最新资讯</div>
        <div @click="$navTo('premiumMall', { activeIndex: '1' })">众享商城</div>
        <div @click="$navTo('exploreShop')">探店</div>
      </div>
      <div class="titleBox">
        <div>众享城市生活网</div>
        <div class="phoneBox">电话：<span>4007806016</span></div>
      </div>
      <div class="beian">网站备案号：蜀ICP备17041163号-1 ICP:川B2-20190377</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pageFoot",
  methods: {
    handleClick() {
      this.$parent.handleGoUp();
    },
  },
};
</script>
<style scoped>
.pageFoot {
  width: 100vm;
  height: 168px;
  padding: 30px 0;
  box-sizing: border-box;
  background: #292c2f;
}
.foot {
  width: 960px;
  height: 100%;
  color: #fff;
  /* background: #f0454b; */
  position: relative;
  margin: 0 auto;
  color: #fff;
}

.info {
  height: 26px;
  display: flex;
  justify-content: center;
}

.info div {
  height: 14px;
  line-height: 14px;
  text-align: center;
  font-size: 16px;
  width: 200px;
  border-right: 1px solid #fff;
}

.info div:last-of-type {
  border: none;
}

.titleBox {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
}

.phoneBox {
  height: 30px;
  line-height: 30px;
  text-align: right;
  font-size: 20px;
  color: #fff;
}

.phoneBox span {
  color: #fcc442;
}

.beian {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #d8d8d8;
}
</style>