<template>
  <div>
    <navHead activeId="3"></navHead>
    <div style="width: 100%">
      <div class="goodsBox">
        <div class="goodsInfo">
          <!-- <el-image
            style="width: 220px; height: 220px"
            :src="$showImg(couponDetail.coupon_img)"
            fit="scale - down"
          ></el-image> -->
          <div class="thisImgBox">
            <img :src="$showImg(couponDetail.coupon_img)" alt="" />
          </div>
          <div class="goodsInfoDetail">
            <div class="goodsName">{{ couponDetail.coupon_title }}</div>
            <div class="storeName">
              {{ couponDetail.merchant && couponDetail.merchant.merchant_name }}
            </div>
            <div class="price"><span>￥</span>{{ couponDetail.price }}</div>
            <div class="imgBox">
              <div
                v-for="(item, index) in couponDetail.swiper_img"
                :key="'sgnusfgy' + index"
                v-show="index < 3"
              >
                <img :src="$showImg(item)" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="goods">
          <!-- 店铺其他商品 -->
          <div class="storOtherGoods">
            <div class="thePoint">本店商品</div>
            <div
              class="otherGoodsDetail"
              v-for="(item, index) in merchantList"
              :key="'gayzem' + index"
              @click="goTo(item.id)"
            >
              <!-- <el-image
                style="width: 200px; height: 200px"
                :src="$showImg(item.coupon_img)"
                fit="scale - down"
              ></el-image> -->
              <div class="otherImg">
                <img :src="$showImg(item.coupon_img)" alt="" />
              </div>
              <div style="font-weight: 700">{{ item.coupon_title }}</div>
              <!-- <div>{{ item.merchant.merchant_name }}</div> -->
              <div class="price1"><span>￥</span>{{ couponDetail.price }}</div>
            </div>
          </div>

          <!-- 当前商品详情 -->
          <div class="goodsDetail">
            <div class="goodsDetailTitle">商品详情</div>
            <!-- 图文富文本 -->
            <div class="detail" v-html="couponDetail.detail"></div>

            <!-- 规格 -->
            <!-- <div>
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="date" label="日期" width="180">
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="180">
                </el-table-column>
                <el-table-column prop="address" label="地址"> </el-table-column>
              </el-table>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <i class="el-icon-top goUp" v-if="scroTop" @click="handleGoUp()"></i>
  </div>
</template>

<script>
import navHead from "../components/navHead.vue";
export default {
  components: {
    navHead,
  },
  data() {
    return {
      couponDetail: {},
      merchantList: [],
      scroTop: false,
    };
  },

  created() {
    this.coupon_id = this.$route.query.id;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handledDetail();
  },

  beforeDestroy() {
    console.log("要被销毁了");
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    //优惠券详情
    handledDetail() {
      this.$api.coupon
        .detail({ coupon_id: this.coupon_id })
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.couponDetail = res.data.data;
            this.couponDetail.swiper_img =
              this.couponDetail.swiper_img.split(",");
            this.MerchantList(this.couponDetail.merchant.id);
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    //店铺全部券
    MerchantList(merchant_id) {
      this.$api.coupon
        .MerchantList({ merchant_id })
        .then((res) => {
          // 成功之后的操作
          console.log(res);
          if (res.data.code == 1) {
            this.merchantList = res.data.data.list;
          }
        })
        .catch((res) => {
          // 失败时候的操作
          console.log(res);
        });
    },

    goTo(id) {
      console.log(this.$router);

      let routeUrl = this.router.resolve({
        name: "goodsDetail",
        query: { id: id },
      });
      // console.log(id)
      document.open(routeUrl.href, "_blank");
    },

    //回到顶部
    handleGoUp() {
      this.scroTop = false;
      let Timer = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          clearInterval(Timer);
          return;
        } else {
          document.documentElement.scrollTop = parseInt(
            document.documentElement.scrollTop -
              document.documentElement.scrollTop / 5
          );
        }
      }, 20);
    },

    //监听高度变化
    handleScroll() {
      let sTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (sTop > 500) {
        this.scroTop = true;
      } else {
        this.scroTop = false;
      }
      console.log(sTop);
    },
  },
};
</script>

<style scoped>
.goodsBox {
  width: 960px;
  height: 1000px;
  padding-top: 30px;
  position: relative;
  margin: 0 auto;
}

.goodsInfo {
  height: 280px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.thisImgBox {
  width: 220px;
  height: 220px;
}

.thisImgBox img {
  width: 100%;
  height: 100%;
}

.goodsInfoDetail {
  width: 650px;
}

.goodsName {
  font-size: 24px;
  line-height: 42px;
  height: 84px;
  overflow: hidden;
  font-weight: 700;
}

.storeName {
  font-size: 20px;
  line-height: 36px;
  color: #313131;
}

.otherImg {
  width: 200px;
  height: 200px;
}

.otherImg img {
  width: 100%;
  height: 100%;
}

.price {
  font-size: 24px;
  line-height: 40px;
  color: #df0101;
  /* font-size: 16px; */
}

.price1 {
  font-size: 24px;
  line-height: 40px;
  color: #df0101;
  font-size: 12px;
}

.imgBox {
  height: 60px;
  display: flex;
}

.imgBox > div {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}

.imgBox > div img {
  width: 100%;
  height: 100%;
}

.goods {
  width: 960px;
  position: relative;
  margin: 30px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.storOtherGoods {
  width: 202px;
  /* background: brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.thePoint {
  width: 100%;
  font-size: 18px;
  color: #313133;
  text-align: center;
  line-height: 48px;
}

.otherGoodsDetail {
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  color: #313131;
  box-sizing: border-box;
}

.otherGoodsDetail div {
  line-height: 1.5em;
}

.goodsDetail {
  width: 720px;
  padding: 10px 30px 20px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.goodsDetailTitle {
  width: 100%;
  height: 56px;
  text-align: center;
  line-height: 56px;
  color: #313131;
  font-family: "黑体";
}

.detail {
  width: 680px;
}

.detail >>> img {
  width: 680px !important;
}

.goUp {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 100px;
}
</style>