import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue'
import guolong from '@/components/guolong.vue'
import informationList from '@/view/informationList.vue'
import information from '@/view/information.vue'
import premiumMall from '@/view/premiumMall.vue'
import goodsDetail from '@/view/goodsDetail.vue'
import exploreShop from '@/view/exploreShop.vue'
import shopDetail from '@/view/shopDetail.vue'


Vue.use(VueRouter)


const routes = [

  { //主页
    path: '/',
    name: 'HelloWorld',
    component: HelloWorld,
    meta: {
      title: '主页',
      keepAlive: false
    }
  },
  { //国隆数字
    path: '/guolong',
    name: 'guolong',
    component: guolong,
    meta: {
      title: '国隆数字',
      keepAlive: false

    }
  },

  { //资讯列表
    path: '/informationList',
    name: 'informationList',
    component: informationList,
    meta: {
      title: '最新资讯',
      keepAlive: false
    }
  },
  { //资讯列表
    path: '/information',
    name: 'information',
    component: information,
    meta: {
      title: '资讯详情',
      keepAlive: false
    }
  },
  { //众享商城
    path: '/premiumMall',
    name: 'premiumMall',
    component: premiumMall,
    meta: {
      title: '众享商城',
      keepAlive: false
    }
  },

  { //众享商城详情
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: goodsDetail,
    meta: {
      title: '优惠券详情',
      keepAlive: false
    }
  },

  { //探店列表
    path: '/exploreShop',
    name: 'exploreShop',
    component: exploreShop,
    meta: {
      title: '探店',
      keepAlive: false
    }
  },

  { //店铺详情
    path: '/shopDetail',
    name: 'shopDetail',
    component: shopDetail,
    meta: {
      title: '探店',
      keepAlive: false
    }
  },

]



const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //解决页面跳转不在顶部
  scrollBehavior(to, from, saveTop) { if (saveTop) { return saveTop; } else { return { x: 0, y: 0 } } }
})




//消除重复点击问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
